import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export const firebasePublic = environment.firebasePublic;
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule} from '@angular/fire/compat'
import { AngularFirestoreModule} from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { environment } from '../../environments/environment';

import { CoreRoutingModule } from './core-routing.module';
import { CartComponent } from './cart/cart.component';
import { ReservationComponent } from './reservation/reservation.component';
import { HomeComponent } from './home/home.component';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from "@angular/material/form-field";
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule}  from '@angular/material/expansion';
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from '@angular/material/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatStepperModule} from '@angular/material/stepper';

import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import { NxCardContentModule, NxDeliveryModule, NxFeatureBannerModule, NxHeroAnimationModule, NxMediaQueryModule, NxToolbarModule, NxViewportModule } from 'nx-material';
import { IsakayaSharedModule } from 'projects/isakaya-shared/isakaya-shared.module';
import { NxHolidayHourModule } from 'nx-material/src/public-api';
import { NxLayoutModule } from "../../../../nx-material/src/lib/nx-layout/nx-layout.module";

@NgModule({
  declarations: [
    CartComponent,
    ReservationComponent,
    HomeComponent
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    BrowserAnimationsModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(firebasePublic),
    AngularFirestoreModule,
    AngularFireStorageModule,
    IsakayaSharedModule,
    MatButtonModule,
    FlexLayoutModule,
    FormsModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatListModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    NxCardContentModule,
    NxDeliveryModule,
    NxToolbarModule,
    NxMediaQueryModule,
    NxHeroAnimationModule,
    NxHolidayHourModule,
    NxFeatureBannerModule,
    NxViewportModule,
    NxLayoutModule
],
  exports: [
    RouterModule
  ]
})
export class CoreModule { }
