import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nx-sidenav-container',
  templateUrl: './nx-sidenav-container.component.html',
  styleUrls: ['./nx-sidenav-container.component.scss']
})
export class NxSidenavContainerComponent implements OnInit {
  @Input() opened: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
    
  }

}
