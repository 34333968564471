<section>
  <div class="feature" [ngStyle]="{
    'background-position': data.position,
    
    'background-image': 'url(' + data.image + ')'
  }" 
>
    <div class="title">{{data.title}}</div>
    <div class="summary">{{data.summary}}</div>
  </div>
</section>