import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NxToolbarService } from 'nx-material';
import { CartItem } from 'projects/isakaya-shared/models/cart-item';
import { CartService } from 'projects/isakaya-shared/services/cart.service';

export interface CodeItem {
  id?: string;
}

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  toolbar = {
    title: 'Orders',
    logo: false,
    btnBack: false,
    btnClose: true,
    i18n: 'fr',
    link: '',
    menu: false,
    cart: false,
    visible: true
  };

  english: boolean;


  cartItems: CartItem[] = [];
  totalQuantity: number = 0;
  totalPrice: number = 0;
  taxGST: number;
  taxQST: number;
  grandTotal: number;
  newCode: string;

  panelOpenState = false;

  newCartForm: FormGroup;
  accessCode: string;

  isValid = false; /// if option not selected button is disabled
  screenSize: string;

  
  constructor(
    private _toolbar: NxToolbarService,
    private _cartService: CartService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.webStorage();  
    this._toolbar.updateToolbar(this.toolbar);
    this.cartDetails();
    this.buildForm();
  }  

  webStorage() {
    let data = sessionStorage.getItem('i18n');
    if ( data == 'en') {
      this.english = true;
      this.toolbar.i18n = "en";
      this.toolbar.title = 'Orders';
    } 
    else if ( data == 'fr' ){
      this.english = false;
      this.toolbar.i18n = "fr";
      this.toolbar.title = 'Commandes';
    }
  }

  cartDetails() {
    
    let sum = {amount: 0};
    this.cartItems = this._cartService.cartItems;
    // console.log('cart view', this.cartItems);
    
    // this.cartItems.forEach(element => {
    //   let sum = element.price * element.quantity;
    //   console.log('sum item', sum)
    //   // this.cartItems.push(sum);
    // });
    this._cartService.totalPrice.subscribe(
      data => {
      this.totalPrice = data;
      this.taxGST = Math.round(data * 5) / 100;
      this.taxQST = Math.round(data * 9.975) / 100;
      this.grandTotal = this.totalPrice + this.taxGST + this.taxQST;
    });

    this._cartService.totalQuantity.subscribe(
      data => this.totalQuantity = data
    );

    this._cartService.calculateTotalPrice();
  }

  ///// Form code
  buildForm() {  
    this.newCartForm = this.fb.group({
      id: ['']      
    });
    

    

    // this.checkValue();
  }

  checkValue() {
    this.accessCode = "02abb4"
    let code: string;
    code = this.newCartForm.get('id').value
    if(this.accessCode == code) {
      return this.isValid = true;
    }
  }
  
  incrementQuantity(cartItem: CartItem){
    this._cartService.incrementQuantity(cartItem);
  }

  decrementQuantity(cartItem: CartItem){
    this._cartService.decrementQuantity(cartItem);
  }

  removeItem(cartItem: CartItem){
    console.log('remove item', cartItem);
    
    this._cartService.remove(cartItem);
  }

}
