import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NxCardItemComponent } from './nx-card-item.component';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [
    NxCardItemComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule
  ],
  exports: [
    NxCardItemComponent
  ]
})
export class NxCardItemModule { }
