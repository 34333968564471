<div #wrapper class="wrapper" 
  [ngStyle]="{'background-color': data.bgColor, 'color': data.fontColor }">
  <div class="header">
    <h1>{{data.title}}</h1>
  </div>
  <div class="container">
    <ng-container *ngFor="let item of data.date" >
      <div class="frame" [ngClass]="{'frame_border': item.date === today }">
        <div class="date">{{item.date | number: '2.0'}}</div>
        <div class="hour">
          <p>{{item.day}}</p>
          <h2>{{item.hour}}</h2>
        </div>
      </div>
    </ng-container>
  </div>
</div>