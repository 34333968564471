import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nx-card-item',
  templateUrl: './nx-card-item.component.html',
  styleUrls: ['./nx-card-item.component.scss']
})
export class NxCardItemComponent implements OnInit {
  @Input() data: any;
  @Input() language: boolean;
  @Input() screen: string;

  border: boolean = false;
  constructor() { }

  ngOnInit(): void {
    if (this.data.limited == true) {this.border = true};
    if (this.data.new == true) {this.border = true};
  }

}
