<nx-toolbar *ngIf="screen?.position < 800" class="sticky" [layout]="2"></nx-toolbar>

<div class="wrapper" [ngSwitch]="english">
  <!-- Header Content -->
  <header>
    <nx-hero-animation [hero]="currentHero" *ngSwitchCase="true"></nx-hero-animation>
    <nx-hero-animation [hero]="currentHero" *ngSwitchCase="false"></nx-hero-animation>
  </header>

  <!-- Main Content -->
  <main>
    <!-- Holidays Hour -->
    <section class="full_black" [ngStyle]="{'background-color': holidayHourFr.bgColor}">
      <nx-holiday-hour *ngSwitchCase="true" [data]="holidayHour" [border]="false"></nx-holiday-hour>
      <nx-holiday-hour *ngSwitchCase="false" [data]="holidayHourFr" [border]="false"></nx-holiday-hour>
    </section> 

    <!-- Experience -->
    <!-- <section>
      <div class="grid grid__content">
        <div 
        [ngStyle.xs]="{'min-height.px': 450}"
        [ngStyle.sm]="{'min-height.px': 500}"
        [ngStyle.md]="{'min-height.px': 500}"
        [ngStyle.lg]="{'min-height.px': 500}"
        [ngStyle.xl]="{'height.px': 700}">
          <nx-card-content *ngSwitchCase="true" [data]="culture" [layout]="3" [border]="true"></nx-card-content>
          <nx-card-content *ngSwitchCase="false" [data]="cultureFr" [layout]="3" [border]="true"></nx-card-content>
        </div>
      </div>
    </section> -->

    <!-- <section>
      <div class="grid grid__content">
        <div 
        [ngStyle.xs]="{'min-height.px': 450}"
        [ngStyle.sm]="{'min-height.px': 500}"
        [ngStyle.md]="{'min-height.px': 300}"
        [ngStyle.lg]="{'min-height.px': 300}"
        [ngStyle.xl]="{'height.px': 400}">
          <nx-card-content *ngSwitchCase="true" [data]="holiday" [layout]="1" [border]="true"></nx-card-content>
          <nx-card-content *ngSwitchCase="false" [data]="holidayFr" [layout]="1" [border]="true"></nx-card-content>
        </div>
      </div>
    </section> -->

    <!-- Valentin Day -->
    <!-- <section>
      <div class="grid grid__content">
        <div>
          <nx-card-content *ngSwitchCase="true" [data]="saintValentin" [layout]="3" [border]="true"></nx-card-content>
          <nx-card-content *ngSwitchCase="false" [data]="saintValentinFr" [layout]="3" [border]="true"></nx-card-content>
        </div>
      </div>
    </section> -->

    <!-- Dining Take Away -->
    <!--
    <div class="feature" [ngStyle]="{'height.px': this.screen?.height * .75 }">
      <nx-feature-banner [data]="featureImage"></nx-feature-banner>
    </div>
    -->


    
    <section>
      <!-- <section [ngStyle]="{'height.px': this.screen?.height}"> -->
        <nx-layout [data]="screen" [margin]="'1% 0% 0% 0%'">
          <div>
            <nx-card-content *ngSwitchCase="true" [data]="ourFood" [layout]="1" [border]="false"></nx-card-content>
            <nx-card-content *ngSwitchCase="false" [data]="ourFoodFr" [layout]="1" [border]="false"></nx-card-content>
          </div>
        </nx-layout>
        <nx-layout [data]="screen" [margin]="'1% 0% 0% 0%'">
          <div>
            <nx-card-content *ngSwitchCase="true" [data]="takeout" [layout]="2" [border]="false"><button mat-stroked-button color="primary" (click)="clickMenu()">MENU</button></nx-card-content>
            <nx-card-content  *ngSwitchCase="false" [data]="takeoutFr" [layout]="2" [border]="false"><button class="mat-button" mat-stroked-button color="primary" (click)="clickMenu()">MENU</button></nx-card-content>
          </div>
        </nx-layout>

    </section>

    <!-- Delivery -->
    <section>
      <div class="delivery" [ngStyle]="{'height.px': this.screen?.height * .80 }">
        <nx-delivery>
          <div header>
            <div class="frame">
              <img class="icon" src="../../../assets/images/outline_delivery_dining_white_24dp.png" alt="">
              <h1 *ngSwitchCase="true">Delivery by</h1>
              <h1 *ngSwitchCase="false">Livraison par</h1>
            </div>
          </div>
          <div button>
            <div>
              <button class="btn-delivery" mat-stroked-button (click)="clickUberEats()">
                <img class="partner-logo" src="../../../assets/images/1280px-Uber_Eats_2020_logo.svg.png" alt="">  
              </button>
            </div>
            <div>
              <button class="btn-delivery" mat-stroked-button (click)="clickDoorDash()">
                <img class="partner-logo" src="../../../assets/images/2560px-DoorDash_Logo.svg.png" alt="">
              </button>  
            </div>            
          </div>
        </nx-delivery>
      </div>
    </section>

    <!-- About Us -->

    <section>
        <!-- <div>
          <nx-card-content *ngSwitchCase="true" [data]="aboutUs" [layout]="1" [border]="false"></nx-card-content>
          <nx-card-content *ngSwitchCase="false" [data]="aboutUsFr" [layout]="1" [border]="false"></nx-card-content>
        </div>
        <div>
          <div class="review">
            <a target="_blank" href="https://lactualite.com/art-de-vivre/le-resto-du-jeudi-midi-le-bistro-isakaya/">
              <img src="../../../assets/images/logo-lactualite.svg" width="auto" height="30px" alt="">
            </a>
            <a target="_blank" href="https://tastet.ca/2019/10/18/bistro-isakaya-lasalle/">
              <img src="../../../assets/images/tastet_logo_txt_black.svg" width="auto" height="30px" alt="">
            </a>
          </div>
        </div> -->
      <div class="grid">
        <div class="grid_one">
          <ng-container *ngFor="let about of msgAbout">
            <div class="text">
            <h1>
              <span *ngSwitchCase="true">{{about.title.en}}</span>
              <span *ngSwitchCase="false">{{about.title.fr}}</span>
            </h1>
            <ng-container *ngSwitchCase="true">
              <span *ngFor="let text of about.message.en"><p>{{text}}</p></span>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <span *ngFor="let text of about.message.fr"><p>{{text}}</p></span>
            </ng-container>
            </div>
          </ng-container>
          <div class="review">
            <a target="_blank" href="https://lactualite.com/art-de-vivre/le-resto-du-jeudi-midi-le-bistro-isakaya/">
              <img src="../../../assets/images/logo-lactualite.svg" width="auto" height="30px" alt="">
            </a>
            <a target="_blank" href="https://tastet.ca/2019/10/18/bistro-isakaya-lasalle/">
              <img src="../../../assets/images/tastet_logo_txt_black.svg" width="auto" height="30px" alt="">
            </a>
          </div>
        </div>
        <div class="grid_two">
          <div class="image"><img class="img" src="../../../assets/images/about_us_sm.jpg" alt=""></div>
        </div>

      </div><!-- grid end -->


    </section>

  <!-- google maps-->
	<article>
		<iframe 
			src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11200.957986766236!2d-73.6173519!3d45.4246733!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc91a49b38ef82d%3A0x5908f235854c8afe!2sBistro+Isakaya!5e0!3m2!1sen!2sca!4v1562595963956!5m2!1sen!2sca" 
			frameborder="0" style="border:0" allowfullscreen
			[ngStyle.xs]="{'min-height.px': 450}"
			[ngStyle.sm]="{'min-height.px': 500}"
			[ngStyle.md]="{'min-height.px': 650}"
			[ngStyle.lg]="{'min-height.px': 1000}"
			[ngStyle.xl]="{'min-height.px': 1250}"
			>
		</iframe>
	</article>

  </main>

  <!-- Footer Content -->
  <footer>
    <app-footer [language]="english"></app-footer>
  </footer>
</div>