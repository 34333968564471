import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nx-holiday-hour',
  templateUrl: './nx-holiday-hour.component.html',
  styleUrls: ['./nx-holiday-hour.component.scss']
})
export class NxHolidayHourComponent implements OnInit {
  @Input() data: any;
  @Input() border: boolean;


  today: any;
  todayDate: any;
  constructor() { }

  ngOnInit(): void {
    this.checkToday();

  }

  checkToday(): void {
    this.todayDate = new Date();
    // this.todayDate.setDate(this.todayDate.getDate());
    // console.log(this.todayDate);
    this.today = this.todayDate.getDate();
    // console.log(this.today);
  }

}
