<!-- <mat-drawer-container class="container">
  <mat-drawer mode="side" opened class="drawer">
    <ng-content select="[nav]"></ng-content>
  </mat-drawer>
  <mat-drawer-content class="content">
    <ng-content select="[content]"></ng-content>
  </mat-drawer-content>
</mat-drawer-container> -->


<mat-sidenav-container class="container">
  <mat-sidenav mode="side" opened class="drawer"><ng-content select="[nav]"></ng-content></mat-sidenav>
  <mat-sidenav-content  class="content"><ng-content select="[content]"></ng-content></mat-sidenav-content>
</mat-sidenav-container>