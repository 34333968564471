import { DatePipe } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { AngularFireAuthModule, SETTINGS } from '@angular/fire/compat/auth';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NxViewportModule } from 'nx-material';
import { IsakayaSharedModule } from 'projects/isakaya-shared/isakaya-shared.module';


@Injectable() export class MyHammerConfig extends HammerGestureConfig {
  constructor() {
    super();
    this.overrides = <any>{
      'pinch': { enable: false },
      'rotate': { enable: false },
      'swipe': { enable: true }
    } 
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    IsakayaSharedModule,
    NxViewportModule
  ],
  providers: [
    DatePipe,
    { provide: SETTINGS, useValue: {} },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
