import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NxFeatureBannerComponent } from './nx-feature-banner.component';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [
    NxFeatureBannerComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule
  ],
  exports: [
    NxFeatureBannerComponent
  ]
})
export class NxFeatureBannerModule { }
