import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nx-pos-button',
  templateUrl: './nx-pos-button.component.html',
  styleUrls: ['./nx-pos-button.component.scss']
})
export class NxPosButtonComponent implements OnInit {
  @Input() data: any;
  @Input() language: boolean;
  @Input() screen: string;

  border: boolean = false;
  constructor() { }

  ngOnInit(): void {
    if (this.data.limited == true) {this.border = true};
    if (this.data.new == true) {this.border = true};
  }

}
