import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nx-hero-basic',
  templateUrl: './nx-hero-basic.component.html',
  styleUrls: ['./nx-hero-basic.component.scss']
})
export class NxHeroBasicComponent implements OnInit {
  @Input() image: string;

  constructor() { }

  ngOnInit(): void {
  }

}
