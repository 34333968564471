import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NxCardContentComponent } from './nx-card-content.component';



@NgModule({
  declarations: [
    NxCardContentComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NxCardContentComponent
  ]
})
export class NxCardContentModule { }
