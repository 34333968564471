import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

declare let gtag:Function;

@Component({
  selector: 'app-root',
  template: `
  <nx-viewport>
    <router-outlet></router-outlet>
  </nx-viewport>
  `,
  styles: []
})
export class AppComponent {


  constructor(
    router: Router) {

    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-0LWEMTD0KT', {
        'page_path': event.urlAfterRedirects
      });

    })
    sessionStorage.clear();
  }




}
