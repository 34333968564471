import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nx-feature-banner',
  templateUrl: './nx-feature-banner.component.html',
  styleUrls: ['./nx-feature-banner.component.scss']
})
export class NxFeatureBannerComponent implements OnInit {
  @Input() data: any;


  constructor() { }

  ngOnInit(): void {
    // console.log(this.data);
    
  }

}
