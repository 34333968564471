import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NxHeroAnimationService {

  private _heroData = new BehaviorSubject<any>('');
  currentData = this._heroData.asObservable();

  constructor() { }
  
  updateMedia(data: any) {   
    this._heroData.next(data);  // other option ({url: data})
    // console.log("Hero service >>", data);
  }
}
