import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NxToolbarComponent } from './nx-toolbar.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';



@NgModule({
  declarations: [
    NxToolbarComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatBadgeModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule
  ],
  exports: [
    NxToolbarComponent
  ]
})
export class NxToolbarModule { }

