import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NxToolbarService {

  private toolbar = new BehaviorSubject<any>('');
  private screen = new BehaviorSubject<any>('');

  currentToolbar = this.toolbar.asObservable();
  currentScreen =  this.screen.asObservable();
  
  constructor(
    private _activeRoute: ActivatedRoute
  ) { }

  updateToolbar(toolbar: any) {
    sessionStorage.setItem('i18n', toolbar.i18n)
    this.toolbar.next(toolbar)
    // console.log("Toolbar Service", this.toolbar.value); 
  }

  updateScreen(screen: any) {
    this.screen.next(screen)
  }

  getSessionLanguage() {
    const i18n = sessionStorage.getItem('i18n');
    return i18n;
  }



}

// Model
export interface Toolbar {
  title: string,
  logo: boolean,
  logoUrl: string,
  btnBack: boolean,
  btnClose: boolean,
  link: string,
  menu: boolean,
  cart: boolean,
  language: boolean,
  i18n: string, // language intl
  visible: boolean,
  sidenav: boolean,
  menuTabs: boolean
  color: string
}