<nx-toolbar></nx-toolbar>
<ng-container [ngSwitch]="english">

<!-- Content Wrapper -->  
<div class="wrapper">
  <header>  
  </header>

  <main>
    <ng-container *ngIf="cartItems.length === 0">
      <div class="container center" >
        <div *ngSwitchCase="true" class="cart-empty">No Orders</div>
        <div *ngSwitchCase="false" class="cart-empty">Aucun commandes</div>
      </div>
    </ng-container>

    <ng-container *ngIf="cartItems.length > 0">
      <div class="header-label">
        <div class="cnt-qty">
          <span *ngSwitchCase="true">Qty</span>
          <span *ngSwitchCase="false">Qté</span>
        </div>
        <div class="cnt-item">
          <span *ngSwitchCase="true">Item</span>
          <span *ngSwitchCase="false">Item</span>
        </div>
        <div class="cnt-price">
          <span *ngSwitchCase="true">Price</span>
          <span *ngSwitchCase="false">Prix</span>
        </div>
      </div>
      <div class="container">
        <mat-accordion>
          <mat-expansion-panel (opened)="panelOpenState = true"
                               (closed)="panelOpenState = false" *ngFor="let item of cartItems">
            <mat-expansion-panel-header style="height: 48px; margin: 5px 0;">
              <mat-panel-title style="font-size: 18px; height: auto">
               <div [ngStyle]="{'width.px': 30, 'text-align':'left'}">{{ item.quantity }}</div>
               <div [ngStyle]="{'width': '100%', 'padding':'0 5px'}">
                <div>{{ item.name }}</div>
                <div [ngStyle]="{'font-weight': 300, 'font-size':'14px'}" *ngIf="item.option != null ">
                  <span *ngSwitchCase="true">{{ item.option.en }}</span>
                  <span *ngSwitchCase="false">{{ item.option.fr }}</span>
                </div>
                <div fxLayout="row" [ngStyle]="{'font-weight': 300, 'font-size':'.7em', 'color':'red'}" *ngIf="item.note != '' ">
                  <div *ngFor="let note of item.note" [ngStyle]="{'padding-right.px': 10}">
                    {{ note }}
                  </div>
                </div>
              </div>
               <div [ngStyle]="{'width.px': 60, 'text-align':'right'}">{{ item.amount | currency }}</div>
              </mat-panel-title>
            </mat-expansion-panel-header>
              <mat-action-row>
                <ng-container *ngIf="item.quantity > 1">
                  <button mat-icon-button (click)="decrementQuantity(item)"><mat-icon>remove</mat-icon></button>
                </ng-container>
                <button mat-icon-button (click)="incrementQuantity(item)"><mat-icon>add</mat-icon></button>
                <button mat-button color="warn" (click)="removeItem(item)"><mat-icon>delete</mat-icon></button>
              </mat-action-row>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <div class="cnt-amount">
        <div class="cnt-left"></div>
        <div class="cnt-label">
        <div class="cnt-label-txt">
          <span *ngSwitchCase="true">Amount</span>
          <span *ngSwitchCase="false">Montant</span>
        </div>
        <div class="cnt-label-txt">
          <span *ngSwitchCase="true">GST</span>
          <span *ngSwitchCase="false">TPS</span>
        </div>
        <div class="cnt-label-txt">
          <span *ngSwitchCase="true">QST</span>
          <span *ngSwitchCase="false">TVQ</span>
        </div>
        <div class="cnt-label-txt">Total</div>
        </div>
        <div class="cnt-label">
          <div class="cnt-total">{{totalPrice | currency}}</div>
          <div class="cnt-total">{{taxGST | currency}}</div>
          <div class="cnt-total">{{taxQST | currency}}</div>
          <div class="cnt-total">{{grandTotal | currency}}</div>
        </div>
      </div>
    </ng-container>
    
  </main>
  <div class="footer-note" *ngIf="cartItems.length > 0">
    <ng-container *ngSwitchCase="true">
      <p>* Please call us to place the above listed orders.</p>
    </ng-container>
    <ng-container *ngSwitchCase="false">
      <p>* Veuillez nous appeler pour passer les commandes énumérées ci-dessus.</p>
    </ng-container>
  </div>
  <!-- <app-footer [language]="toolbar.english"></app-footer> -->
</div> <!-- Wrapper End-->

</ng-container>