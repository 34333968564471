<article>
  <section>
    <div class="logo">
    <img src="{{logo}}">
    </div>
    <div class="center"><h3>Sign in</h3></div>
    <!-- <div class="center"><p>with your Email Account</p></div> -->

    <form [formGroup]="loginForm">
      <mat-form-field class="input-box">
        <input matInput type="email" placeholder="Email" formControlName="email" (keydown.enter)="nextTab($event)">
      </mat-form-field>     
      <mat-form-field  class="input-box">
        <input matInput type="password" id="password" placeholder="Password" formControlName="password" (keydown.enter)="login()">
      </mat-form-field>
    
    </form>
    <div class="action-btn">
      <button mat-raised-button color="primary" (click)="login()">Sign in</button>
    </div>
  </section>
</article>