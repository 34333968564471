import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() language: boolean;
  @Input() showNote: boolean = false;

  location = {
    en: 'Address',
    fr: 'Adresse'
  };

  social = {
    en: 'Follow us',
    fr: 'Suivez-nous'
  };

  constructor(
    // private _toolbarService: ToolbarService
  ) { }

  ngOnInit(): void {
  }

}
