<ng-container [ngSwitch]="language">

    <ng-container *ngIf="showNote">
    <div class="grid-wrapper gray">
      <div class="grid-layout">
        <div class="grid-item"
          [ngStyle.xl]="{'justify-self': 'start'}"
          [ngStyle.lg]="{'justify-self': 'start'}"
          [ngStyle.md]="{'justify-self': 'start'}"
          [ngStyle.sm]="{'justify-self': 'start'}"
          [ngStyle.xs]="{'justify-self': 'start'}">
          <ng-content select=".note-left"></ng-content>
        </div>
        <div class="grid-item"
          [ngStyle.xl]="{'justify-self': 'end'}"
          [ngStyle.lg]="{'justify-self': 'end'}"
          [ngStyle.md]="{'justify-self': 'end'}"
          [ngStyle.sm]="{'justify-self': 'end'}"
          [ngStyle.xs]="{'justify-self': 'start'}">
          <ng-content select=".note-right"></ng-content>
        </div>
      </div>
    </div>
  </ng-container>
  
  <div class="grid-wrapper black pad-top">
    <div class="grid-layout">
      <div class="grid-rows">
        <img src="../../../../assets/images/logo_white.png" width="auto" height="36px" alt="Logo">
        <div class="title">
          <span *ngSwitchCase="true">{{location.en}}</span>
          <span *ngSwitchCase="false">{{location.fr}}</span>
        </div>
        <div class="address" 
          [ngStyle.xl]="{'font-size.px': 20}"
          [ngStyle.lg]="{'font-size.px': 20}"
          [ngStyle.md]="{'font-size.px': 20}"
          [ngStyle.sm]="{'font-size.px': 20}"
          [ngStyle.xs]="{'font-size.px': 14}">
          8178 Boul. Champlain, LaSalle, QC  H8P 1B4
        </div>
        <div class="phone"
          [ngStyle.xl]="{'font-size.px': 24}"
          [ngStyle.lg]="{'font-size.px': 24}"
          [ngStyle.md]="{'font-size.px': 24}"
          [ngStyle.sm]="{'font-size.px': 24}"
          [ngStyle.xs]="{'font-size.px': 18}">
          514.366.8808
        </div>
      </div>
  
      <div></div>
      
      <div class="grid-rows">
        <div class="title">
          <span *ngSwitchCase="true">{{social.en}}</span>
          <span *ngSwitchCase="false">{{social.fr}}</span>
        </div>
        <div class="social">
          <img src="../../../../assets/images/google-64px.png" class="social-logo" alt="Google">
          <a href="https://www.facebook.com/pages/category/Sushi-Restaurant/Isakaya-122703825238/"><img src="../../../../assets/images/facebook-64px.png" class="social-logo" alt="Facebook"></a>
            <a href="https://www.yelp.ca/biz/bistro-isakaya-montr%C3%A9al-4"><img src="../../../../assets/images/yelp-64px.png" class="social-logo" alt="Yelp"></a>
            <a href="https://www.tripadvisor.ca/Restaurant_Review-g155032-d786585-Reviews-Bistro_Isakaya-Montreal_Quebec.html?m=19905"><img src="../../../../assets/images/tripadvisor-64px.png" class="social-logo" alt="Trip Advisor"></a>
            <img src="../../../../assets/images/wechat-64px.png" class="social-logo" alt="Wechat">
        </div>
        <div>&nbsp;</div>
      </div>
      
    </div>
  </div>
  
  <div class="grid-wrapper black">
    <div class="grid-layout">
      <div class='copyright'>&copy; 2025 All Rights Reserved, Bistro Isakaya. Website by LL </div>
    </div>
  </div>
  
  </ng-container>
  