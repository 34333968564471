<!-- <mat-toolbar class="layout" [ngStyle]="{'background-color': toolbar?.color}"  -->
<mat-toolbar class="layout" 
  [ngClass]="{
    'layout__one' : layout === 1, 
    'layout__two': layout ===  2
  }"
>
  <!-- left icon button -->
<ng-container>
  <!-- <ng-template [ngIf]="toolbar?.sidenav == true">
    <button mat-icon-button><mat-icon (click)="drawerToggle()">menu</mat-icon></button>
  </ng-template> -->
  <ng-content select="[left]"></ng-content>
  <ng-template [ngIf]="toolbar?.btnBack == true">
    <button mat-icon-button><mat-icon (click)="back()">arrow_back</mat-icon></button>
  </ng-template>
  <ng-template [ngIf]="toolbar?.btnClose == true">
    <button mat-icon-button><mat-icon (click)="close()">close</mat-icon></button>
  </ng-template>
</ng-container>

<ng-container>
  <ng-template [ngIf]="toolbar?.logo == true"><img class="logo" src="{{toolbar.logoUrl}}"></ng-template>
  <ng-template class="title" [ngIf]="toolbar?.logo == false">{{toolbar?.title}}</ng-template>
</ng-container>
<span fxFlex></span>
<ng-container>
  <div *ngIf="screen?.name == 'xs'; else menu_label">
    <button mat-icon-button title="Menu" *ngIf="toolbar?.menu == true" (click)="viewMenu()" arial-label=""><mat-icon>local_dining</mat-icon></button>
  </div>
  <ng-template #menu_label>
    <button mat-button class="button-rounded" title="Menu" *ngIf="toolbar?.menu == true" (click)="viewMenu()"><mat-icon>local_dining</mat-icon><span class="menu-icon">Menu</span></button>
  </ng-template>
</ng-container>

<ng-container>
<button mat-icon-button title="Cart" *ngIf="toolbar?.cart == true" (click)="orderView()">
<ng-container *ngIf="quantity > 0; else noBadge">
<mat-icon class="active-item" matBadge="{{quantity}}" matBadgeColor="accent">assignment</mat-icon>
</ng-container>
<ng-template #noBadge>
<mat-icon matBadgeColor="accent">assignment</mat-icon>
</ng-template>

</button>
</ng-container>
<ng-container *ngIf="toolbar?.language == true">
  <ng-template [ngIf]="toolbar?.i18n == 'en'">
    <button mat-button class="button-rounded" (click)="lang_fr()" arial-label="Francais">
      <img src='./assets/icons/language_french.png' width='36px' height='auto'>
    </button>
  </ng-template>
  <ng-template [ngIf]="toolbar?.i18n == 'fr'">
    <button mat-button  class="button-rounded"  (click)="lang_en()">
      <img src='./assets/icons/language_english.png' width='36px' height='36px'>
    </button>
  </ng-template>
</ng-container>

  <ng-content></ng-content>
</mat-toolbar>