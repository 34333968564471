import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NxViewportComponent } from './nx-viewport.component';



@NgModule({
  declarations: [
    NxViewportComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule
  ],
  exports: [
    NxViewportComponent
  ]
})
export class NxViewportModule { }
