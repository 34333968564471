import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NxMediaQueryService {
  
  constructor() { }

  private _mediaQuery = new BehaviorSubject<any>('');
  mediaQuery$ = this._mediaQuery.asObservable();

  updateMedia(data: any) {   
    this._mediaQuery.next(data);  // other option ({url: data})
    // console.log("Mediaquery service >>", data);
  }
  
}

export interface Media {
  name: string;
  width: number;
  height: number;
  orientation: string;
  class: string;
  position: number;
}
