import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate, keyframes, query, stagger, group, animateChild } from '@angular/animations';
import { NxHeroAnimationService } from './nx-hero-animation.service';

@Component({
  selector: 'nx-hero-animation',
  templateUrl: './nx-hero-animation.component.html',
  styleUrls: ['./nx-hero-animation.component.scss'],
  animations: [
    // HERO Animation
     trigger('heroAnimation', [
       state('portrait', style({opacity: 1, transform: 'translateY(0)'})),
       state('landscape', style({opacity: 1, transform: 'translateX(0)'})),
       transition('* => portrait', [
         group([
           animate('1500ms ease-out', keyframes([
             style({opacity: 0, transform: 'translateY(100%)'}),
             style({opacity: 1, transform: 'translateY(0)'}),
             ])
           ),
           query('@heroTitle', animateChild()),
           query('@heroSubTitle', animateChild())
         ])
       ]),
       transition('* => landscape', [
         group([
           animate('1500ms ease-out', keyframes([
             style({opacity: 0, transform: 'translateX(100%)'}),
             style({opacity: 1, transform: 'translateX(0)'}),
             ])
           ),
           query('@heroTitle', animateChild()),
           query('@heroSubTitle', animateChild())
         ])
       ]),
     ]),
     // HERO Text
     trigger('heroTitle', [
       state('portrait', style({opacity: 1, transform: 'translateX(0)', backgroundSize: '300% 300%'})),
       state('landscape', style({opacity: 1, transform: 'translateX(0)', backgroundSize: '300% 300%'})),
       transition('* <=> *', 
         animate('2500ms 1500ms ease-in-out', keyframes([
           style({opacity: 0, transform: 'translateX(0)', backgroundSize: '0% 0%'}),
           style({opacity: 0.2, transform: 'translateX(10%)', textShadow: '0 0 12px #fff', letterSpacing: '8px'}),
           style({opacity: 0.4, textShadow: '0 0 0px #fff'}),
           style({opacity: 0.6, textShadow: '0 0 12px #fff'}),
           style({opacity: 0.8, transform: 'translateX(0)', textShadow: '0 0 0px #fff'}),
           style({opacity: 0.9, textShadow: '0 0 12px #fff', letterSpacing: '0px'}),
           style({opacity: 1, textShadow: '0 0 0px #fff'})
         ]))
       ),
     ]),
     trigger('heroSubTitle', [
       state('portrait', style({opacity: 1, transform: 'translateX(0)', backgroundSize: '300% 300%'})),
       state('landscape', style({opacity: 1, transform: 'translateX(0)', backgroundSize: '300% 300%'})),
       transition('* <=> *', 
         animate('2000ms 3000ms ease', keyframes([
           style({opacity: 0, transform: 'translateX(0)', backgroundSize: '0% 0%'}),
           style({opacity: 0.2, transform: 'translateX(10%)', textShadow: '0 0 12px #fff', letterSpacing: '8px'}),
           style({opacity: 0.4, textShadow: '0 0 0px #fff'}),
           style({opacity: 0.6, textShadow: '0 0 12px #fff'}),
           style({opacity: 0.8, transform: 'translateX(0)', textShadow: '0 0 0px #fff'}),
           style({opacity: 0.9, textShadow: '0 0 12px #fff', letterSpacing: '0px'}),
           style({opacity: 1, textShadow: '0 0 0px #fff'})
         ]))
       ),
     ]),
   ]  
})
export class NxHeroAnimationComponent implements OnInit {
  @Input() hero: any;

  screen = {
    name: 'xs',
    width: 0,
    height: 0,
    orientation: 'none',
    class: '',
    position: 0
  }
  constructor(
    private _heroAnimationService: NxHeroAnimationService
  ) { }

  ngOnInit(): void {
    this._heroAnimationService.currentData.subscribe( hero => {
      this.screen = hero;
    });
  }

}
