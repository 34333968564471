<ng-container>
  <div #container class="card"
    [ngClass]="{
      'card__border' : border === true, 
      'card__left': layout ===  1 || layout === 3 || layout === 5,
      'card__right': layout === 2 || layout === 4
    }">
    
    <div class="content content__center content__resize content__m10">
      <div class="position">
      <h1>{{data.title}}</h1>
      <p>{{data.summary}}</p>
      <div class="extra"><ng-content></ng-content></div>
      </div>
    </div>

    <div class="image"
    [ngStyle]="{'background-image': 'url(' + data.image + ')'}"
    [ngClass]="{
      'image__right' : layout === 1,
      'image__left' : layout === 2,
      'image__left-angle' : layout === 3,
      'image__angle-right' : layout === 4,
      'image__arrow-left' : layout === 5
    }"></div>

  </div>
</ng-container>


