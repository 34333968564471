<div class="wrapper">
  <div class="hero">
<ng-container>
  <div [ngClass]="screen.class" [@heroAnimation]="screen.orientation"
  [ngStyle.xs]="{'background-image': 'url(' + hero.image.xs + ')'}"
  [ngStyle.sm]="{'background-image': 'url(' + hero.image.md + ')'}"
  [ngStyle.md]="{'background-image': 'url(' + hero.image.lg + ')'}"
  [ngStyle.lg]="{'background-image': 'url(' + hero.image.xl + ')'}"
  [ngStyle.xl]="{'background-image': 'url(' + hero.image.xl + ')'}"
  >
    <div class="hero-text" >
        <h1 [@heroTitle]="screen.orientation" >{{hero.title}}</h1>
        <h2 [@heroSubTitle]="screen.orientation">{{hero.subtitle}}</h2>
    </div>
  </div>
</ng-container>

</div>
</div>