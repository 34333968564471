import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private dataRef: AngularFirestoreCollection<Home>;
  data$: Observable<Home[]>;

  constructor(
    private afs: AngularFirestore) {}

  getData() {
    this.dataRef = this.afs.collection('home', ref => ref.orderBy('name'));

    this.data$ = this.dataRef.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Home;
        data.id = a.payload.doc.id;
        return data;
      }))
    );   
    return this.data$; 
  }
}


// Model
export interface Home {
  name: string;
  title: any;
  message: any;
  image?: any;
  id?: string;
}
