import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nx-layout',
  templateUrl: './nx-layout.component.html',
  styleUrls: ['./nx-layout.component.scss']
})
export class NxLayoutComponent implements OnInit {

  @Input() data: any;
  @Input() border: boolean;
  @Input() height: string;
  @Input() margin: string;


  // height: string;

  constructor(
    // private _viewport: NxViewportService
  ) {}

  ngOnInit(): void {
    console.log(this.margin);
    
    // this._viewport.viewport$.subscribe( data => {
    //   this.viewport = data;
    // });
    // console.log(this.viewport.height);
    if (this.data !== undefined) {
      // let newHeight = this.data.height * 0.60;
      // console.log(newHeight);
      // this.height = newHeight + 'px';
      console.log(this.height);

    }
  }

}
