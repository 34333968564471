import { Injectable } from '@angular/core';
import { of, Subject, Observable } from 'rxjs';
import { CartItem } from '../models/cart-item';


@Injectable({
  providedIn: 'root'
})
export class CartService {
  cartItems: CartItem[] = [];
  totalPrice: Subject<number> = new Subject<number>();
  totalQuantity: Subject<number> = new Subject<number>();

  orderId: number = 0;

  
  constructor() { }

  addToCart(newItem: CartItem) {
    //check already item in the cart 
    let alreadyExistsInCart: boolean = false;
    let existingCartItem: CartItem = undefined;


    // if ( newItem.option != '') {
    //   if ( newItem.option.includes('Nigiri (2 mcx)') || newItem.option.includes('Nigiri (2 pcs)')) {
    //     let nigiri = newItem.option.substring(0, 6);
    //     newItem.option = nigiri;
    //   }
    // }
    
    // console.log('cart', newItem);
    
    
    if(this.cartItems.length > 0){
      //find the item in the cart based on id

      existingCartItem = this.cartItems.find(tempCartItem => {

        if(tempCartItem.id === newItem.id) {
          if( (newItem.note.length > 0) && (newItem.option != null) ) {
            // console.log('inside FIRST argument');
            let tempNote = tempCartItem.note.join();
            let newNote = newItem.note.join();
            return (tempCartItem.option === newItem.option) && (tempNote === newNote);
          } 
          else if (newItem.option != null) {
            // console.log('inside SECOND argument');
            return tempCartItem.option.en === newItem.option.en;
          } 
          else if (newItem.note.length > 0) {
            // console.log('inside THIRD argument');
            let tempNote = tempCartItem.note.join();
            let newNote = newItem.note.join();
            return tempNote === newNote;
          } 
          else {
            // console.log('inside FOUR argument');
            return tempCartItem.id === newItem.id;
          }
        }
        

      });
      
      //check if we found it
      alreadyExistsInCart = (existingCartItem != undefined)

    } 

    // console.log('existingCartItem outside IF', existingCartItem);

    if(alreadyExistsInCart){
      //increment the quantity
      existingCartItem.quantity = existingCartItem.quantity + newItem.quantity;
      existingCartItem.amount = existingCartItem.amount + newItem.amount;
    }
    else {
      //add to cart item array
      this.orderId += 1;
      newItem.order = this.orderId;
      this.cartItems.push(newItem);  
    }

    this.calculateTotalPrice();
  }

  calculateTotalPrice() {
    let totalPriceValue: number = 0;
    let totalQuantityValue: number = 0;

    for(let currentCartItem of this.cartItems){
      totalPriceValue += currentCartItem.quantity * currentCartItem.price;
      totalQuantityValue += currentCartItem.quantity;
    }

    // console.log(`Total price: ${totalPriceValue}, Total quantity: ${totalQuantityValue}`);
    
    this.totalPrice.next(totalPriceValue);
    this.totalQuantity.next(totalQuantityValue);
  }

  incrementQuantity(cartItem: CartItem){
    let amount = cartItem.amount / cartItem.quantity;
    cartItem.quantity++;
    cartItem.amount += amount;

    
    this.calculateTotalPrice();
 
  }

  decrementQuantity(cartItem: CartItem){
    let amount = cartItem.amount / cartItem.quantity;
    cartItem.quantity--;
    cartItem.amount -= amount;

    if (cartItem.quantity === 0) {
      this.remove(cartItem);
    }else {
      this.calculateTotalPrice();
    }
  }

  remove(cartItem: CartItem){
    const itemIndex = this.cartItems
                          .findIndex(
                            tempCartItem => tempCartItem.order === cartItem.order
                          );

    if (itemIndex > -1) {
      this.cartItems.splice(itemIndex, 1);
      this.calculateTotalPrice();
    }
  }

  getItems() {
    return this.cartItems;
  }

  clearCart() {
    this.cartItems = [];
    return this.cartItems;
  }
}



