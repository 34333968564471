<ng-container [ngSwitch]="language">

  <div [ngClass]="{'sold-out': data.soldOut, 'in-stock': !data.soldOut }">
  <div class="card" [ngClass]="{'white-border': !border, 'red-border': border}">
    <div class="data">
      <div class="name">{{data?.name}}</div>
      <div class="price">{{data?.price | currency}}</div>
      <div class="summary" *ngSwitchCase="true">{{data?.summary.en}}</div>
      <div class="summary" *ngSwitchCase="false">{{data?.summary.fr}}</div>
    </div>
    <div class="rectangle"></div>
    <div class="circle"></div>
    <ng-container *ngIf="data?.limited">
      <div class="tag" *ngSwitchCase="true"><h6>Limited</h6></div>
      <div class="tag" *ngSwitchCase="false"><h6>Limitée</h6></div>
    </ng-container>
    <ng-container *ngIf="data?.new">
      <div class="tag" *ngSwitchCase="true"><h6>New</h6></div>
      <div class="tag" *ngSwitchCase="false"><h6>Nouveau</h6></div>
    </ng-container>
    <div class="image" [ngStyle]="{'background-image': 'url('+ data?.image +');'}"></div>
  </div>
</div>

</ng-container>