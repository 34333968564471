import { Component, HostListener, OnInit} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { Media, NxMediaQueryService } from './nx-media-query.service';

@Component({
  selector: 'nx-media-query',
  templateUrl: './nx-media-query.component.html',
  styleUrls: ['./nx-media-query.component.scss']
})
export class NxMediaQueryComponent implements OnInit {
  private mediaSubscription: Subscription;
  private activeMediaQuery: any;
  
  screen: Media = {
    name: 'xs',
    width: 0,
    height: 0,
    orientation: 'none',
    class: '',
    position: 0
  }

  constructor(
    private mediaObserver: MediaObserver,
    private _mediaService: NxMediaQueryService
    ) {
      this.onResize();
      this.onWindowScroll();

    }

    
  @HostListener('window:scroll', ['eScroll'])
  onWindowScroll(eScroll?:any) {    
    this.screen.position = window.scrollY;
    this.mediaUpdate(); 
  }

  @HostListener('window:resize', ['eHeight', 'eWidth']) 
  onResize(eHeight?: any, eWidth?: any) {
    this.screen.width = window.innerWidth;
    this.screen.height = window.innerHeight;
    if (window.innerHeight > window.innerWidth) {
      // this.screen.height = window.innerHeight * 9 / 16;
      this.screen.orientation = 'portrait';
      // console.log('activeMediaQuery', this.screen.orientation);
      this.screen.class = this.screen.orientation + '-' + this.screen.name;
    } else {
      this.screen.orientation = 'landscape';
      // console.log('activeMediaQuery', this.screen.orientation);
      this.screen.class = this.screen.orientation + '-' + this.screen.name;
    }
    this.mediaUpdate(); 
  }


  ngOnInit(): void {
    this.mediaSubscription = this.mediaObserver
      .asObservable()
      .subscribe((change) => {
        change.forEach((item) => {
          this.activeMediaQuery = item
            ? `'${item.mqAlias}' = (${item.mediaQuery})`
            : '';
            
          if (item.mqAlias === 'xs') {
            this.screen.name = item.mqAlias;
            this.screen.class = this.screen.orientation + '-' + item.mqAlias;
            this.mediaUpdate(); 
          }
          if (item.mqAlias === 'sm') {
            this.screen.name = item.mqAlias;
            this.screen.class = this.screen.orientation + '-' + item.mqAlias;
            this.mediaUpdate(); 
          }
          if (item.mqAlias === 'md') {
            this.screen.name = item.mqAlias;
            this.screen.class = this.screen.orientation + '-' + item.mqAlias;
            this.mediaUpdate(); 
          }
          if (item.mqAlias === 'lg') {
            this.screen.name = item.mqAlias;
            this.screen.class = this.screen.orientation + '-' + item.mqAlias;
            this.mediaUpdate(); 
          }
          if (item.mqAlias === 'xl') {
            this.screen.name = item.mqAlias;
            this.screen.class = this.screen.orientation + '-' + item.mqAlias;
            this.mediaUpdate(); 
          }
          // console.log('activeMediaQuery', this.activeMediaQuery);
        });
      });
    // this.onResize();    
  }

  mediaUpdate() {
    console.log('nx-media-query', this.screen.class);
    this._mediaService.updateMedia(this.screen);
  }


  ngOnDestroy(): void {
    this.mediaSubscription.unsubscribe();
  }

}