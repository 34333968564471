export const environment = {
  production: true,
  firebasePublic: {
    apiKey: "AIzaSyBxFP2xY92sZQg6hdC4urY_tX71jGrBoZo",
    authDomain: "bistroisakaya-db05d.firebaseapp.com",
    databaseURL: "https://bistroisakaya-db05d.firebaseio.com",
    projectId: "bistroisakaya-db05d",
    storageBucket: "bistroisakaya-db05d.appspot.com",
    messagingSenderId: "558115316948"
  } 
};
