import { Component, HostListener, OnInit} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { Viewport, NxViewportService } from './nx-viewport.service';

@Component({
  selector: 'nx-viewport',
  template: `
    <div class="wrapper">
      <ng-content></ng-content>
    <div>
  `,
  styles: [
    `.wrapper { display: flex; flex-direction: column;margin: 0; width: 100%; min-height:100%;}`
  ]
})
export class NxViewportComponent implements OnInit {
  
  private mediaSubscription: Subscription;
  private activeMediaQuery: any;
  
  screen: Viewport = {
    name: 'xs',
    width: 0,
    height: 0,
    orientation: 'none',
    class: '',
    position: 0
  }

  constructor(
    private mediaObserver: MediaObserver,
    private _viewport: NxViewportService
    ) {
      this.onResize();
      this.onWindowScroll();

    }

    
  @HostListener('window:scroll', ['eScroll'])
  onWindowScroll(eScroll?:any) {    
    this.screen.position = window.scrollY;
    this.viewportMedia(); 
  }

  @HostListener('window:resize', ['eHeight', 'eWidth']) 
  onResize(eHeight?: any, eWidth?: any) {
    this.screen.width = window.innerWidth;
    this.screen.height = window.innerHeight;
    if (window.innerHeight > window.innerWidth) {
      // this.screen.height = window.innerHeight * 9 / 16;
      this.screen.orientation = 'portrait';
      // console.log('activeMediaQuery', this.screen.orientation);
      this.screen.class = this.screen.orientation + '-' + this.screen.name;
    } else {
      this.screen.orientation = 'landscape';
      // console.log('activeMediaQuery', this.screen.orientation);
      this.screen.class = this.screen.orientation + '-' + this.screen.name;
    }
    this.viewportMedia(); 
  }


  ngOnInit(): void {
    this.mediaSubscription = this.mediaObserver
      .asObservable()
      .subscribe((change) => {
        change.forEach((item) => {
          this.activeMediaQuery = item
            ? `'${item.mqAlias}' = (${item.mediaQuery})`
            : '';
            
          if (item.mqAlias === 'xs') {
            this.screen.name = item.mqAlias;
            this.screen.class = this.screen.orientation + '-' + item.mqAlias;
            this.viewportMedia(); 
          }
          if (item.mqAlias === 'sm') {
            this.screen.name = item.mqAlias;
            this.screen.class = this.screen.orientation + '-' + item.mqAlias;
            this.viewportMedia(); 
          }
          if (item.mqAlias === 'md') {
            this.screen.name = item.mqAlias;
            this.screen.class = this.screen.orientation + '-' + item.mqAlias;
            this.viewportMedia(); 
          }
          if (item.mqAlias === 'lg') {
            this.screen.name = item.mqAlias;
            this.screen.class = this.screen.orientation + '-' + item.mqAlias;
            this.viewportMedia(); 
          }
          if (item.mqAlias === 'xl') {
            this.screen.name = item.mqAlias;
            this.screen.class = this.screen.orientation + '-' + item.mqAlias;
            this.viewportMedia(); 
          }
          // console.log('activeMediaQuery', this.activeMediaQuery);
        });
      });
    // this.onResize();    
  }

  viewportMedia() {
    // console.log('nx-media-query', this.screen.height);
    this._viewport.update(this.screen);
  }


  ngOnDestroy(): void {
    this.mediaSubscription.unsubscribe();
  }

}