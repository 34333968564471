import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'nx-form-login',
  templateUrl: './nx-form-login.component.html',
  styleUrls: ['./nx-form-login.component.scss']
})
export class NxFormLoginComponent implements OnInit {
  // emailFormControl = new FormControl('', [
  //   Validators.required,
  //   Validators.email,
  // ]);
  @Input() logo: string;
  @Output() data = new EventEmitter<any>();
  loginForm: FormGroup;


  constructor(
    private fb: FormBuilder,
  ) { }

  login() {
    this.data.emit(this.loginForm.value);
    this.loginForm.reset();
  } 

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.loginForm = this.fb.group({
      email: '',
      password: ''
    });
   
  }

  nextTab(event: any) {
    if (event.keyCode==13) {
      console.log('enter');
      document.getElementById('password').focus();
      // (<any>this.loginForm.get('password')).nativeElement.focus();
      // event.keyCode=9; 
      // return event.keyCode = 9;
    };
  }


}
