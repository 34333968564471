import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nx-delivery',
  templateUrl: './nx-delivery.component.html',
  styleUrls: ['./nx-delivery.component.scss']
})
export class NxDeliveryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
