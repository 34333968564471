<div class="wrapper">
  <section>
    <div class="header">
    <h1 class="title">We're sorry!</h1>
    <h4 class="summary">The page you have requested cannod ne found.</h4>
    </div>
    <div class="msg">
      <p>Maybe the page was moved or deleted, or perhaps you just mistyped the address.</p>
    </div>
  </section>
</div>