import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NxHeroAnimationService, NxToolbarService, NxViewportService } from 'nx-material';
import { HomeService } from '../../data/home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  toolbar = {
    title: '',
    logo: true,
    logoUrl: '../assets/images/logo.png',
    language: true,
    i18n: 'fr',
    menu: true,
    color: "white"
  };



  screen: any;
  english: boolean;

  dataState: any;
  dataList: any;
  heroData: any;
  currentHero = {
    title: '',
    subtitle: '',
    image: '../../../assets/images/blank_image.png'
  };

  // culture: any;

  // culture = {
  //   title: 'Experience',
  //   summary: 'Temporarily Closed, will be opening soon',
  //   image: 'https://firebasestorage.googleapis.com/v0/b/bistroisakaya-db05d.appspot.com/o/images%2Fhome%2Fhome_discover_md.jpg?alt=media&token=ff418638-a85e-4f5a-8719-c3f87e6237c2',
  //   imagexs: 'https://firebasestorage.googleapis.com/v0/b/bistroisakaya-db05d.appspot.com/o/images%2Fhome%2Fhome_discover_xs.jpg?alt=media&token=657290c1-536f-4e27-8b40-4336967a7eb8'
  // }

  holidayHour: any = {
    title: 'CLOSED',
    bgColor: 'black',
    fontColor: 'white',
    date: [
      // {date: 22, day: 'Sunday', hour: 'Open'},
      // {date: 23, day: 'Monday', hour: 'Closed'},
      // {date: 24, day: 'Tuesday', hour: 'Closed'},
      {date: 31, day: 'Monday', hour: 'Closed'},
      {date: 1, day: 'Tuesday', hour: 'Closed'},
      {date: 2, day: 'Wednesday', hour: 'Closed'},
      {date: 3, day: 'Thursday', hour: 'Closed'},
      {date: 4, day: 'Friday', hour: 'Closed'},
      {date: 5, day: 'Saturday', hour: 'Closed'},
      {date: 6, day: 'Sunday', hour: 'Closed'},
    ]
  }

  holidayHourFr: any = {
    title: 'FERMÉE',
    bgColor: 'black',
    fontColor: 'white',
    date: [
      // {date: 22, day: 'Dimanche', hour: 'Ouvert'},
      // {date: 23, day: 'Lundi', hour: 'Fermée'},
      // {date: 24, day: 'Mardi', hour: 'Fermée'},
      {date: 31, day: 'Lundi', hour: 'Fermée'},
      {date: 1, day: 'Mardi', hour: 'Fermée'},
      {date: 2, day: 'Mercredi', hour: 'Fermée'},
      {date: 3, day: 'Jeudi', hour: 'Fermée'},
      {date: 4, day: 'Vendredi', hour: 'Fermée'},
      {date: 5, day: 'Samedi', hour: 'Fermée'},
      {date: 6, day: 'Dimanche', hour: 'Fermée'},

    ]
  }

  saintValentin: any = {
    title: 'Valentine\'s Day',
    summary: ' Tuesday february 14, we will be open for take out',
    image: '../../../assets/images/saint-valentin.jpg'
  }

  saintValentinFr: any = {
    title: 'Saint-Valentin',
    summary: 'Mardi 14 février, nous serons ouvert pour emporter',
    image: '../../../assets/images/saint-valentin.jpg'
  }

  holiday: any = {
    title: '',
    summary: '',
    image: '../../../assets/images/holiday_platter.jpg'
  }

  holidayFr: any = {
    title: '',
    summary: '',
    image: '../../../assets/images/holiday_platter.jpg'
  }

  culture: any = {
    title: '',
    summary: '',
    image: '../../../assets/images/blank_image.png'
  }

  cultureFr: any = {
    title: '',
    summary: '',
    image: '../../../assets/images/blank_image.png'
  }

  ourFood = {
    title: 'OUR FOOD',
    summary: 'Authentic Japanese food',
    image: '../../../assets/images/home_menu_plater_md.jpg'
  }

  ourFoodFr = {
    title: 'NOTRE NOURRITURE',
    summary: 'Cuisine japonaise authentique',
    image: '../../../assets/images/home_menu_plater_md.jpg'
  }

  diningRoom = {
    title: 'Dining Room',
    summary: 'Temporarily Closed, will be opening soon',
    image: '../../../assets/images/home_menu_plater_md.jpg'
  }

  diningRoomFr = {
    title: 'Salle à Manger',
    summary: 'Fermé temporairement, ouvrira bientôt',
    image: '../../../assets/images/home_menu_plater_md.jpg'
  }

  takeout = {
    title: 'TAKEOUT',
    summary: 'From sushi to kitchen menu to be enjoyed at home',
    image: '../../../assets/images/home_takeout_platter_md.jpg'
  }

  takeoutFr = {
    title: 'EMPORTER',
    summary: 'Du sushi au menu de cuisine à déguster à la maison',
    image: '../../../assets/images/home_takeout_platter_md.jpg'
  }

  aboutUs = {
    title: 'ABOUT US',
    summary: 'Bistro Isakaya opened it door on Avenue du Parc in 1999, located in downtown Montreal, by Shigeo Minagawa. Been serving authentic Japanese food for more than two decades. Was on Radio Canada, La Presse, The Gazette, Voir, Mirror ... Recommended by Flavourville Lesley Chesterman\'s guide, Debeur, Zagat, Where Chefs Eat...',
    image: '../../../assets/images/about_us_sm.jpg'
  }

  aboutUsFr = {
    title: 'À PROPOS',
    summary: 'Bistro Isakaya a ouvert ses portes sur l\'avenue du Parc en 1999, situé au centre-ville de Montréal, par Shigeo Minagawa. Servir une cuisine japonaise authentique depuis plus de 20 ans. Était sur Radio Canada, La Presse, The Gazette, Voir, Mirror... Recommandé par Flavourville Lesley Chesterman\'s guide, Debeur, Zagat, Where Chefs Eat...',
    image: '../../../assets/images/about_us_sm.jpg'
  }


  featureImage = {
    title: 'Maguro-Tataki',
    summary: 'Thon sashimi poêlé',
    image: 'https://firebasestorage.googleapis.com/v0/b/bistroisakaya-db05d.appspot.com/o/images%2Fhome%2Ftakeout_maguro_tataki_md.jpg?alt=media&token=a089dee1-5a65-4860-8afa-0e08d947b8f2',
    position: '50% 75%',
    size: '150%',
    screen: 'xs'
  }

  scrollPosition: number = 0;

  msgCulture: any;
  msgAnnounce: any;
  msgDelivery: any;
  msgAbout: any;



  constructor(
    private _router: Router,
    private _toolbar: NxToolbarService,
    private _viewport: NxViewportService,
    private _heroAnimation: NxHeroAnimationService,
    private _home: HomeService,
  ){}

  ngOnInit(): void {
    this._toolbar.updateToolbar(this.toolbar);
    this.getData();

  }

  getData(){
    this._toolbar.currentToolbar.subscribe( data => this.toolbar = data);

    // Viewport Service
    this._viewport.viewport$.subscribe( data => {
      this.screen = data;
      console.log(this.screen.name);
      
      this._toolbar.updateScreen(this.screen);
      this.featureImage.screen = this.screen.name;
      this._heroAnimation.updateMedia(data)
      
    });
    this.dataState = this._home.getData()
    .subscribe(
      data => { 
        if (data != undefined) {
          this.dataList = data;
          this.applyFilters();
        }
        
      }
    );

  }

  private applyFilters() {
    let hero: any;
    hero = this.dataList.filter((val: { name: string; }) => {
      return val.name == "Hero";
    });
    hero = hero[0];
    if (hero != undefined) {
      this._toolbar.currentToolbar.subscribe( data => {
        this.toolbar = data;
        if (data.i18n === "en") {
          this.english = true;
          this.currentHero.title = hero.title.en;
          this.currentHero.subtitle = hero.subtitle.en;
          this.currentHero.image = hero.image;
        } else {
          this.english = false;
          this.currentHero.title = hero.title.fr;
          this.currentHero.subtitle = hero.subtitle.fr;
          this.currentHero.image = hero.image;
        }
      });
    }

    this.msgAnnounce = this.dataList.filter((val: { type: string, announce: boolean; }) => {
      return val.type == "Announce" && val.announce == true;
    });
    this.msgAbout = this.dataList.filter((val: { name: string; }) => {
      return val.name == "About";
    });

    const cultureData = this.dataList.filter((val: { name: string; }) => {
      return val.name == "culture";
    });
    
    this.homeCulture(cultureData);
  }

  homeCulture(data: any) {
    const keys = Object.keys(data);

    if(keys.length === 1 ) {
      this.cultureFr.title = data[0].title.fr;
      this.cultureFr.summary = data[0].message.fr;
      this.cultureFr.image = data[0].image.web;
      this.culture.title = data[0].title.en;
      this.culture.summary = data[0].message.en;
      this.culture.image = data[0].image.web;   
    }

    // console.log(this.msgCulture);
    // console.log(this.culture);
    
  }

  clickMenu() {
    this._router.navigate(['/menu/']);   
  }

  clickUberEats() {
    window.open('https://www.ubereats.com/ca/montreal/food-delivery/bistro-isakaya/nv1SdCpWRK-JJLVCZCJzjg');
  }

  clickDoorDash() {
    window.open('https://www.doordash.com/store/bistro-isakaya-montr%C3%A9al-2556759/')
  }




}
