import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NxHeroAnimationComponent } from './nx-hero-animation.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [
    NxHeroAnimationComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule
  ],
  exports: [
    NxHeroAnimationComponent
  ]
})
export class NxHeroAnimationModule { }
