import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NxToolbarService } from './nx-toolbar.service';

@Component({
  selector: 'nx-toolbar',
  templateUrl: './nx-toolbar.component.html',
  styleUrls: ['./nx-toolbar.component.scss']
})
export class NxToolbarComponent implements OnInit {
  @Input() layout: number;


  screen: any;
  toolbar: any;
  quantity;
  badgeState;

  constructor(
    private _toolbarService: NxToolbarService,
    private _location: Location,
    private _router: Router,
    private _activeRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this._toolbarService.currentToolbar.subscribe(data => this.toolbar = data);
    this._toolbarService.currentScreen.subscribe(data => this.screen = data);
    
    // this.getBadge();
  }

  getBadge() {
    
  //   this._cartService.totalQuantity.subscribe( total => {
  //     this.quantity= total;
  //     // console.log('badge', this.quantity)
  //     let num = this.quantity;
  //     let string = num.toString();
  //     // console.log('badge string', string)
  //     sessionStorage.setItem('order', string)
  //   });

  //   if(this.quantity === undefined){
  //     let getOrder = sessionStorage.getItem('order')
  //     this.quantity = Number(getOrder);
  //   }
    
  }

  drawerToggle() {
    
  }

  back() {
    this._router.navigate([this.toolbar.link]);
  }

  close() {
    this._location.back();
  }

  lang_en() {
    this.toolbar.i18n = "en";
    this._toolbarService.updateToolbar(this.toolbar)
  }

  lang_fr() {
    this.toolbar.i18n = "fr";
    this._toolbarService.updateToolbar(this.toolbar)
  }

  viewMenu() {
    this._router.navigate(['/menu/list']);   
  }
  orderView() {
    this._router.navigate(['/order']);    
  }

  cartView() {
    this._router.navigate(['/cart']);   
  }

}
